import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import Todd from '../../images/directors5.jpg'
import BG from '../../images/awards-bg.jpg'

class ToddHearne extends React.Component {
	render() {
		const siteTitle = 'Todd Hearne'
		const siteDescription = this.props.data.site.siteMetadata.siteDescription

		return (
			<Layout title={siteTitle} location={this.props.location}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div class="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">Todd Hearne</h3>
								<div className="row">
									<div className="col-12">
										<p>
											Todd Hearne oversees our Newcastle operations. Having 18
											years’ experience as a Chartered Accountant and a
											Registered Tax Agent, Todd’s focus is on providing a high
											level of service to WSC Group clients.
										</p>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-6 col-md-12">
										<p>
											Todd is passionate about assisting small to medium sized
											business clients achieve their goals. He understands the
											importance of communication and touching base regularly to
											ensure their affairs are in order and that they are on
											track to reach their goals.
										</p>
										<p>
											Todd also specialises in accounting and tax advice for
											clients with Self-Managed Superfunds.
										</p>
									</div>
									<div className="col-lg-6 col-md-12">
										<img
											src={Todd}
											className="img-fluid"
											alt="David Shaw CEO"
										/>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default ToddHearne

export const ToddHearnePageQuery = graphql`
	query ToddHearnePageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
